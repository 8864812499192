import en from './en.json';
import da from './da.json';
import de from './de.json';

const locales = {
	en,
	da,
	de,
};

export default locales;
